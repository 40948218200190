import { type IEvent } from '~/types/index'
import { type IError } from '~/types/index'

export function useEventStoreFront() {
  // Get all events from DB
  const getList = async (body: { page: number; limit: number }) => {
    try {
      const data = await useFetchAuth('/api/v1/front/event/get-list', {
        method: 'POST',
        body: body,
      })
      return data as unknown as IEvent
    } catch (e) {
      return e as IError
    }
  }
  // Get one event from DB, used on single event page
  const getOne = async (slug: string) => {
    try {
      const data = await useFetchAuth(`/api/v1/front/event/${slug}`, {
        method: 'POST',
      })
      return data
    } catch (e) {
      return e as IError
    }
  }

  const getSliders = async (body: { showInHomeSlider: boolean }) => {
    try {
      const data = await useFetchAuth('/api/v1/front/event/get-sliders', {
        method: 'POST',
        body: body,
      })
      return data as unknown as IEvent
    } catch (e) {
      return e as IError
    }
  }

  const search = async (body: { search: string }) => {
    try {
      const data = await useFetchAuth('/api/v1/front/event/search', {
        method: 'POST',
        body: body,
      })
      return data as unknown as IEvent
    } catch (e) {
      return e as IError
    }
  }

  const getTags = async () => {
    try {
      const data = await useFetchAuth('/api/v1/front/event/get-tags', {
        method: 'POST',
      })
      return data as unknown as IEvent
    } catch (e) {
      return e as IError
    }
  }

  return {
    getList,
    getSliders,
    getOne,
    getTags,
    search,
  }
}
